import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
export default function PortfolioPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 36 }) {
        id
        content
        title
        positions {
          job {
            location
            title
            fileUpload {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const { content } = data.wpPage
  return (
    <Layout>
      <Seo title="Portfolio" />
      <div className="max-w-4xl mx-auto py-12 md:py-32 px-4">
        <h1 className="text-2xl md:text-5xl font-bold leading-tight text-center pb-8 md:mb-16">
          Portfolio Strategy
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="space-y-4 portfolio"
        />
      </div>
      <Footer />
    </Layout>
  )
}
